<template>
    <div class="wrapper grid row-50">
        
        <ActionBar title="Roles">
            <Button text="Create Role" size="small" @click="saveRole({})"/>
        </ActionBar>


        <div class="roles-content grid row-20">
            
            <PageSearchInput v-model="_pageManager.query.search" placeholder="Search roles by name"/>

            <div class="item-list">
                <TableView :head="tableHead" :size="columnsSizes" v-if="(_pageManager.list.length > 0)" :loading="isLoading(['GetRoles'])">
                    <TableRow 
                        :size="columnsSizes" 
                        :key="role._id" 
                        v-for="(role, key) of _pageManager.list" 
                        @dblclick="saveRole(role, key)" 
                        :menu="[{ name: 'Edit role', emit: 'edit' }, { name: 'Remove role', emit: 'remove', type: 'remove' }]"
                        @edit="saveRole(role, key)"
                        @remove="removeRole(role, key)"
                    >
                        <RoleItemVue :role="role"/>
                    </TableRow>
                </TableView>

                <div v-else>
                    <div v-if="isLoading(['GetRoles'])" class="spinner-flex"><Spinner/></div>
                    <div v-else><NoDataMessage text="Nothing found"/></div>
                </div>
            </div>

            <div class="pagination">
                <Pagination :manager="_pageManager" @pageChange="(page) => { _pageManager.query.page = page }"/>
            </div>
            
        </div>
        
    </div>
</template>

<script>
import RoleItemVue from '../../components/Roles/RoleItem.vue';
import SaveRole from '../../components/Roles/SaveRole.vue';
import PaginationJS from '../../mixins/Pagination'
import Pagination from '../../mixins/Pagination/components/Pagination.vue'

    export default {
        mixins: [PaginationJS],
        components: {
            RoleItemVue,
            Pagination
        },
        data() {
            return {
                tableHead: [
                    { name: 'Role name' },
                    { name: 'Permissions' },
                    { name: 'Users' },
                    { name: '' }
                ],
                columnsSizes: [ 'minmax(150px, 1fr)','minmax(150px, 1fr)','minmax(100px, 150px)', '34px' ],
                roles: {},
            }
        },
        watch: {
            "_pageManager.query.search": {
                deep: true,
                handler() {
                    this._pageManager.query.page = 1;
                }
            },
            "_pageManager.query": {
                deep: true,
                handler() {
                    this.GetRoles();
                }
            }
        },
        methods: {
            GetRoles() {
                let query = new URLSearchParams(this._pageManager.query).toString();
                this.ajax('GetRoles', {
                    url: '/roles?' + query,
                    method: 'GET',
                }, (err, body) => {
                    if(!err) {
                        this._pageManager.pages = body.pages;
                        this._pageManager.count = body.count;
                        this._pageManager.list = body.list;
                        this._pageManager.page = body.page;
                        this._pageManager.hasNextPage = body.hasNextPage;
                        this._pageManager.hasPrevPage = body.hasPrevPage;
                    }
                });
            },
            async removeRole(role, key) {
                let confirmRemove = await this.$ShowConfirm('Do you really wish to remove this role?');
                if(!confirmRemove) return;

                await this.ajax('RemoveRole', {
                    url: '/roles',
                    method: 'DELETE',
                    data: {
                        _id: role._id
                    }
                }, (err, body) => { });

                this._pageManager.list.splice(key, 1);
                this.GetRoles();
            },
            saveRole(role = {}, key = null) {

                role = JSON.parse(JSON.stringify(role));

                let modal = this.$ShowModal({
                    title: role._id ? 'Edit Role' : 'Create Role',
                    description: role._id ? 'Edit role name and permissions' : 'Enter role name and select permissions',
                    component: SaveRole,
                    props: {
                        item: role,
                        errors: {}
                    }
                });

                modal.onConfirm(async (props) => {

                    modal.processing(true);

                    await this.ajax('SaveRole', {
                        url: '/roles',
                        method: 'POST',
                        data: props.item
                    }, (err, body) => {
                        
                        if(err) {
                            if(body.errors) modal.setProp('errors', body.errors);
                            else this.$ShowAlert(body.message || 'Something went wrong. Please, try again!');
                            return;
                        }
                        modal.close();
                        this.GetRoles();
                    });

                    modal.processing(false);

                });

            }
        },
        mounted() {
            this.GetRoles();
        }
    }
</script>

<style lang="scss" scoped>

.wrapper {
    max-width: 1300px;
    margin: 0 auto;
}

.item-list {
    border-bottom: 1px solid $borderColor;
    padding: 0 0 20px 0;
}

.pagination {
    display: flex;
    // justify-content: flex-end;
}
</style>