export default {
    data() {
        return {
            _pageManager: {
                pages: 1,
                page: 1,
                count: 0,
                hasNextPage: false,
                hasPrevPage: false,
                list: [],
                query: {
                    page: 1
                }
            }
        }
    },
    computed: {

    },
    methods: {
        
    }
}