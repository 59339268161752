<template>
    <div>{{ role.name }}</div>
    <div>{{ Object.keys(role.permissions).join(', ') }}</div>
    <div>{{ role.usersCount }} {{ role.usersCount === 1 ? 'user' : 'users' }}</div>
</template>

<script>
    export default {
        props: ['role']
    }
</script>

<style lang="scss" scoped>

</style>