<template>
    <div class="pagination">
        <div class="total">Total {{ pageManager.count }}</div>
        <div class="page-navigation">
            <button :disabled="(!pageManager.hasPrevPage && pageManager.page <= 1)" @click="prevPage"><i class="fa-solid fa-caret-left"></i></button>
            <button :disabled="(!pageManager.hasNextPage || pageManager.page >= pageManager.pages)" @click="nextPage"><i class="fa-solid fa-caret-right"></i></button>
        </div>
        <div>Page {{ pageManager.page }} of {{ pageManager.pages }}</div>
    </div>
</template>

<script>
    export default {
        props: ['manager'],
        data() {
            return {
                pageManager: this.manager
            }
        },
        methods: {
            nextPage() {
                this.pageManager.page++;
                this.$emit('pageChange', this.pageManager.page);
            },
            prevPage() {
                this.pageManager.page--;
                this.$emit('pageChange', this.pageManager.page);
            }
        },
        watch: {
            manager() {
                this.pageManager = this.manager;
            }
        }
    }
</script>

<style lang="scss" scoped>
.pagination {
    display: flex;
    gap: 20px;
    align-items: center;
}

.page-num {
    display: flex;
    gap: 10px;
    align-items: center;
}

.total {
    font-size: 12px;
}

.page-navigation {
    display: flex;
    gap: 5px;
    align-items: center;
    button {
        width: 34px;
        height: 34px;
        border-radius: 13px;
        border: 1px solid #eee;
        color: #000;
        background: #fff;
        cursor: pointer;
        &:disabled {
            background: #eee;
            color: #ccc;
        }
    }
}

.current-page {
    width: 38px;
    height: 34px;
    border: 0;
    background: #fff;
    border-radius: 13px;
    text-align: center;
    outline: none;
    border: 1px solid #eee;
}
</style>