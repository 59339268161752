<template>
    <div class="save-role">

        <Input v-model="item.name" placeholder="Enter appropriate role name" name="Role name" :error="errors.name"/>

        <div class="input">
            <div class="_header">
                <label>Permissions</label>
                <span class="_error">{{ errors.permissions }}</span>
            </div>

            <div class="permissions-picker">
                <div class="permissions-list" v-if="selectedPermissions.length > 0">
                    <div :key="key" v-for="(item, key) of selectedPermissions">{{ item }}</div>
                </div>
                <div class="permissions-list" v-else>No permissions selected</div>
                <a href="" @click.prevent="selectPermissions">{{ selectedPermissions.length > 0 ? 'Edit permissions' : 'Select permissions' }}</a>
            </div>
        </div>

    </div>
</template>

<script>
import PermissionsSelector from './PermissionsSelector.vue';

    export default {
        props: ['modal', 'item', 'errors'],
        computed: {
            selectedPermissions() {
                let array = [];
                if(!this.item.permissions) return array;
                for(const item of Object.keys(this.item.permissions)) {
                    let temp = `${item}`;
                    if(this.item.permissions[item].length > 0) temp += ' (' + this.item.permissions[item].join(', ') + ')';
                    array.push(temp);
                }
                return array;
            }
        },
        methods: {
            selectPermissions() {

                let permissions = this.item.permissions || {};

                let modal = this.$ShowModal({
                    title: 'Select permissions',
                    description: 'Select appropriate permissions for this role',
                    component: PermissionsSelector,
                    width: '300px',
                    confirm: 'Save',
                    props: {
                        permissions: JSON.parse(JSON.stringify(permissions))
                    }
                });

                modal.onConfirm((data) => {
                    if(data && data.permissions) {
                        this.item.permissions = data.permissions;
                    }
                    modal.close();
                });

            }
        },
    }
</script>

<style lang="scss" scoped>
.save-role {
    padding: 20px;
    display: grid;
    row-gap: 15px;
}

.permissions-picker {
    display: grid;
    row-gap: 10px;
    justify-items: start;
}

.permissions-list {
    color: $textShade;
}
</style>