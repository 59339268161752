<template>
    <div class="permissions-list">
        <div class="permissions-wrapper">


            <div v-if="isLoading(['GetUserPermissions'])" class="spinner-flex"><Spinner/></div>

            <div class="items" v-else>
                <div class="item" :key="item" v-for="item of Object.keys(availablePermissions)">

                    <div class="header">
                        <div class="name">{{ item }}</div>
                        <Switch :value="permissions[item]" @change="(val) => { switchRoleCategory(val, item) }"/>
                    </div>


                    <div class="item-options" v-if="permissions[item]">
                        <div class="option" :key="key" v-for="(opt, key) of availablePermissions[item]">
                            <div class="checkbox"><Checkbox :val="opt" v-model="permissions[item]"/></div>
                            <div class="name">{{ opt }}</div>
                        </div>
                    </div>
                    
                </div>
            </div>
            

        </div>
    </div>
</template>

<script>
    export default {
        props: ['permissions'],
        data() {
            return {
                availablePermissions: {}
            }
        },
        methods: {
            switchRoleCategory(value, item) {
                if(value === true) {
                    this.permissions[item] = this.permissions[item] || [];
                } else {
                    delete this.permissions[item];
                }
            },
            getUserPermissions() {
                this.ajax('GetUserPermissions', {
                    url: '/UserPermissions',
                    method: 'GET'
                }, (err, body) => {
                    if(!err) this.availablePermissions = body;
                });
            }
        },
        mounted() {
            this.getUserPermissions();
        }
    }
</script>

<style lang="scss" scoped>
.permissions-wrapper {
    padding: 20px;
}

.permissions-list {
    overflow-y: auto;
    max-height: 300px;
}

.item {

}

.item-options {
    padding-left: 10px;
    display: grid;
    row-gap: 5px;
    .option {
        display: flex;
        align-items: center;
        gap: 10px;
    }
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
    .name {
        font-size: 14px;
        font-weight: 500;
    }
}
</style>